import auth0 from 'auth0-js'

/* eslint no-restricted-globals: 0*/

let { REACT_APP_DOMAIN, REACT_APP_ClientID } = process.env;
export default class Auth0 {
  auth0 = new auth0.WebAuth({
    domain: REACT_APP_DOMAIN,
    clientID: REACT_APP_ClientID,
    redirectUri: 'https://application.startmerchantservices.com/auth/callback',
    audience: `https://${REACT_APP_DOMAIN}/userinfo`,
    apiuri: "http://startmerchantservices.com",
    responseType: 'token id_token',
    scope: 'openid profile'
  });

  login = () => {
    this.auth0.authorize()
  }

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResults) => {
      if (authResults && authResults.accessToken && authResults.idToken) {
        let expiresAt = JSON.stringify((authResults.expiresIn) * 1000 + new Date().getTime());
        localStorage.setItem("access_token", authResults.accessToken);
        localStorage.setItem("id_token", authResults.idToken)
        localStorage.setItem("expires_at", expiresAt);
        location.pathname = "/dashboard"
      } else if (err) {
        location.pathname = '/'
        console.log(err)
      }
    })
  }
  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
  logout = () => {
    this.auth0.logout({
      returnTo: 'http://startmerchantservices.com',
      clientID: REACT_APP_ClientID
    })
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at");

  }
}
