//Appraisal Vision
import React, { Component } from 'react'
import './LandingPage.scss'
import Auth0 from '../Auth0/Auth0'
import axios from 'axios'
import _ from 'lodash'
import createInputs from '../../utils/components/createInputs'
import SendingModal from '../Modal/SendingModal'
import LeadSuccess from '../Modal/LeadSuccess'

class AppVision extends Component {

  state = {
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    Gateway: '',
    salesRep: '',
    accountExecutive: '',
    repEmail: '',
    subRep: '',
    notes: '',
    reason: '',
    file: null,
    data: null,
    preferredAgent: [],
    modalShow: false,
    modalSuccess: false,
  }


  submit = () => {
    const firstName = this.state.firstName.trim()
    const lastName = this.state.lastName.trim()
    const businessName = this.state.businessName.trim()
    const email = this.state.email.trim()
    const phoneNumber = this.state.phoneNumber.trim()
    const Gateway = this.state.Gateway.trim()
    const salesRep = this.state.salesRep.trim()
    const repEmail = this.state.repEmail.trim()
    const subRep = this.state.subRep.trim()
    const accountExecutive = this.state.accountExecutive.trim()
    const notes = this.state.notes.trim()
    const reason = this.state.reason.trim()
    const data = new FormData()
    data.append('file', this.state.file)

    this.setState({ modalShow: true })
    axios.post('/newlead', data, {
      params: {
        firstName: firstName,
        lastName: lastName,
        businessName: businessName,
        email: email,
        phoneNumber: phoneNumber,
        Gateway: Gateway,
        salesRep: salesRep,
        accountExecutive: accountExecutive,
        repEmail: repEmail,
        subRep: subRep,
        notes: notes,
        reason: reason,
        Lead_Source: 'Appraisal Vision',
        partner: true
      },

    }).then(() => {
      this.setState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phoneNumber: '',
        Gateway: '',
        salesRep: '',
        accountExecutive: '',
        repEmail: '',
        subRep: '',
        notes: '',
        reason: '',
        modalShow: false,
        modalSuccess: true,
      })
      this.refs.file.value = ''
    })
  }
  componentDidMount = () => {
    axios.post('/api/getAllPartnerAgents', { company: 'Appraisal Vision' })
      .then(res => {
        res.data.map(agent => {
          if (agent.company === 'Appraisal Vision') {
            this.setState({ preferredAgent: this.state.preferredAgent.concat(agent) })
          }
          return null
        })
      })
  }

  selectOnChange = (event) => {
    if (event.target.value === 'none') {
      this.setState({ salesRep: '', repEmail: '' })
    } else {
      const value = event.target.value

      const findEmail = _.find(this.state.preferredAgent, salesRep => salesRep.name === value)
      const email = findEmail.email
      this.setState({
        salesRep: value,
        repEmail: email,
        subRep: value
      })
    }
  }

  onChange = (e) => {
    const value = e.target.value
    this.setState({ [e.target.name]: value })
  }

  closeModal = () => {
    this.setState({ modalSuccess: false })
  }
  render() {
    const Auth = new Auth0()

    const inputObject = [
      {
        title: 'First Name',
        name: 'firstName',
        type: 'input',
        stateValue: this.state.firstName,
        onEventChange: this.onChange
      },
      {
        title: 'Last Name',
        name: 'lastName',
        type: 'input',
        stateValue: this.state.lastName,
        onEventChange: this.onChange
      },
      {
        title: 'Business Name',
        name: 'businessName',
        type: 'input',
        stateValue: this.state.businessName,
        onEventChange: this.onChange
      },
      {
        title: 'Email Address ',
        name: 'email',
        type: 'input',
        stateValue: this.state.email,
        onEventChange: this.onChange
      },
      {
        title: 'Phone Number',
        name: 'phoneNumber',
        type: 'input',
        stateValue: this.state.phoneNumber,
        placeholder: '8888888888',
        onEventChange: this.onChange
      },
      // {
      //   title: 'Gateway ID',
      //   name: 'Gateway',
      //   type: 'input',
      //   stateValue: this.state.Gateway,
      //   onEventChange: this.onChange
      // },
      {
        title: 'Sales Representative ',
        name: 'salesRep',
        type: 'select',
        stateValue: this.state.salesRep,
        onEventChange: this.selectOnChange,
        salesReps: this.state.preferredAgent
      },
      {
        title: 'Documents',
        type: 'file',
        onEventChange: this.getDocumentToSend,
        ref: 'file'
      },
      {
        title: 'Reason',
        name: 'reason',
        type: 'input',
        stateValue: this.state.reason,
        onEventChange: this.onChange
      },
      {
        title: 'Notes',
        name: 'notes',
        type: 'textArea',
        placeholder: 'Notes go here',
        stateValue: this.state.notes,
        onEventChange: this.onChange,
      }
    ];

    return (

      <div className='landingPage-wrapper' >
        <div className='landingNav'>
          <img src={require('../Login/logo.png')} alt='' />
          <h1 onClick={Auth.login}>Login</h1>
        </div>
        <div className='landingForm'>
          <h1 >New Merchant Submission</h1>
          <div className='form-fields'>
            <div className='left-form'>
              {inputObject.map(input => createInputs(input))}
            </div>
          </div>
          <div className='form-submit-btn'>

            <button onClick={this.submit}> Submit </button>
          </div>
        </div>
        {(
          this.state.modalShow &&
          <SendingModal
            display={this.state.modalShow}
          />
        )}
        {(
          this.state.modalSuccess &&
          <LeadSuccess
            display={this.state.modalSuccess}
            closeModal={this.closeModal}
          />
        )}
      </div>
    )
  }
}
export default AppVision;