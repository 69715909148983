import React, { Component } from 'react'
import './Incentive.scss'
import TopNav from '../TopNav/TopNav'
import { getApprovals } from '../../api/incentiveApi'
import { getAllAgents } from '../../api/dashBoardApi'


class Incentive extends Component {
  state = {
    approvals: null,
    agents: null
  }
  componentDidMount = async () => {
    const currentCompany = this.props.currentCompany
    const approval = await getApprovals(currentCompany)
    const agents = await getAllAgents()
    const filterAgents = agents.data.filter(agent => agent.company === currentCompany)
    this.setState({ approvals: approval, agents: filterAgents })
  }
  createRow = (i, c) => {
    const money = (i.count >= 20 ? 250 : i.count >= 15 ? 150 : i.count >= 10 ? 75 : i.count >= 5 ? 25 : 0)

    return (!i.name ? null :
      <tr className={(c % 2 === 0 ? 'grey' : 'white')}>
        <td> {i.name} </td>
        <td>{i.count} </td>
        {/* <td>$ {((c === 0 && i.count >= 1) ? money + 100 : money)}</td> */}
      </tr>
    )
  }




  render() {
    const { approvals, agents } = this.state;
    const approvalsList = agents?.map(agent => {
      const count = approvals?.filter(lead => lead.Sales_Agent === agent.name || lead.Account_Executive === agent.name)
      return { name: agent.name, count: count.length }
    })
    approvalsList?.sort((a, b) => b.count - a.count)

    return (
      <div>
        <TopNav page={this.props.page} />
        <div className='incentive-wrapper'>
          <table cellSpacing='0' className='incentive-table'>
            <tr >
              <th>Sales Agent</th>
              <th>Apps Approved</th>
              {/* <th>Incentive earned</th> */}
            </tr>
            {approvalsList?.map((i, c) => {
              return this.createRow(i, c)
            })}
          </table>

        </div>
      </div>
    )
  }
}
export default Incentive;