import React, { useState, useEffect } from 'react'
import { Table, } from 'react-bootstrap'
import XLSX from 'xlsx';
import { CSVLink } from "react-csv";

import { getLeadInfo } from '../../api/reportApi';
import months from '../../utils/months'
import headers from './reportHeaders'



const Reports = () => {
  const date = new Date()
  const currentYear = date.getFullYear()
  const [commissionMonth, setCommissionMonth] = useState(`${currentYear}-1-1`)
  const [currentMonth, setCurrentMonth] = useState(`${currentYear - 1}-12-1`)
  const [downloadableFile, setDownloadableFile] = useState([])
  const [conversionRate, setConversionRate] = useState(1)
  const [company, setCompany] = useState('ANET')

  const getDocumentToSend = async (event) => {
    if (event.target.files.length < 1) return
    const files = event.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: null })
      // const filterData = dataParse.map(arr => arr.filter(n => n))
      // const cleanData = filterData.filter(n => n.length > 4)

      makeTableBody(dataParse)
    };
    reader.readAsBinaryString(f)
  }

  const makeTableHeader = () => {
    if (company === 'CYBS') {
      return (
        <tr>
          {headers.headers.map(header => <td>{header}</td>)}
        </tr>
      )
    }
    if (company === 'ANET') {
      return (
        <tr>
          {headers.anetHeaders.map(header => <td>{header}</td>)}
        </tr>
      )
    }
  }

  const makeTableBody = (file) => {
    let mid,
      cybsMid,
      merchantName,
      currency,
      grossSales,
      trans,
      totalIncome,
      totalExpense,
      agentNet
    const revShareType = 'Percent_of_Rev'
    const revShareVariable = .5

    file.map(async (merchant, index) => {
      if (index === 0) {
        merchant.map((title) => {
          if (title === 'Merchant ID' || title === "MID") {
            mid = merchant.indexOf(title)
          }
          if (title === 'Merchant' || title === "DBA") {
            merchantName = merchant.indexOf(title)
          }
          if (title === 'Transactions' || title === 'Net No') {
            trans = merchant.indexOf(title)
          }
          if (title === 'Sales Amount' || title === 'Net Volume') {
            grossSales = merchant.indexOf(title)
          }
          if (title === 'Income' || title === 'Gross Rev') {
            totalIncome = merchant.indexOf(title)
          }
          if (title === 'Agent Net' || title === 'Total Residual Earnings') {
            agentNet = merchant.indexOf(title)
          }
          if (title === 'Expenses' || title === 'Gross Exp') {
            totalExpense = merchant.indexOf(title)
          }
          return null
        })
      };
      try {
        if (Number(merchant[mid])) {
          let income = merchant[totalIncome]
          let incomeToCybs = merchant[agentNet] * revShareVariable
          let grossSalesAmount = merchant[grossSales]
          let totalExpenseAmount = merchant[totalExpense]
          let grossProfit = merchant[agentNet]
          let platformType, processor, approvedDate, mcc, basisPoints, perTransFee, startBpsCost

          const data = await getLeadInfo(merchant[mid])
          cybsMid = data?.Gateway_ID || 'Update MID'
          processor = data?.Processor
          approvedDate = data?.Date_Approved
          mcc = data?.MCC
          currency = data?.Processing_Currency || 'no mid'
          platformType = data?.GW_Platform || 'Update MID'
          basisPoints = data?.Basis_Points
          perTransFee = data?.Per_Transaction
          startBpsCost = data?.START_BPS_Cost


          if (merchant[totalIncome] === 0) {
            income = merchant[agentNet] + merchant[totalExpense]
            income = Math.round(100 * income) / 100
          }
          if (currency === 'CAD') {
            income = income * conversionRate
            income = Math.round(100 * income) / 100;
            incomeToCybs = (grossProfit * conversionRate) * revShareVariable
            incomeToCybs = Math.round(100 * incomeToCybs) / 100;
            grossSalesAmount = grossSalesAmount * conversionRate
            grossSalesAmount = Math.round(100 * grossSalesAmount) / 100;
            totalExpenseAmount = totalExpenseAmount * conversionRate
            totalExpenseAmount = Math.round(100 * totalExpenseAmount) / 100;
            grossProfit = grossProfit * conversionRate
            grossProfit = Math.round(100 * grossProfit) / 100;
          }
          if (currency === 'no mid') {
            income = 0
            incomeToCybs = 0
            grossSalesAmount = 0
            totalExpenseAmount = 0
            grossProfit = 0
          }
          if (company === 'CYBS') {
            setDownloadableFile(prev => [...prev, [
              merchant[mid],
              cybsMid,
              platformType,
              merchant[merchantName],
              currentMonth,
              commissionMonth,
              currency,
              grossSalesAmount,
              merchant[trans],
              basisPoints,
              perTransFee,
              startBpsCost,
              income,
              totalExpenseAmount,
              grossProfit,
              revShareType,
              revShareVariable,
              incomeToCybs]]
            )
          }
          if (company === 'ANET') {
            setDownloadableFile(prev => [...prev, [
              merchant[mid],
              cybsMid,
              platformType,
              merchant[merchantName],
              currentMonth,
              commissionMonth,
              currency,
              grossSalesAmount,
              merchant[trans],
              basisPoints,
              perTransFee,
              startBpsCost,
              income,
              totalExpenseAmount,
              grossProfit,
              revShareType,
              revShareVariable,
              incomeToCybs,
              processor,
              approvedDate,
              mcc
            ]]
            )
          }
        }
      } catch (error) { console.error(error) }
      return null
    })

  }
  const renderBody = () => {
    return (
      downloadableFile.map(merchant => {
        const redStyle = merchant[1] === 'Update MID'
        return (
          <tr style={{
            backgroundColor: redStyle ? 'red' : ''
          }}>
            {merchant.map(i => <td>{i}</td>)}
          </tr>
        )
      })
    )
  }

  const getMonth = () => {
    return (
      <select value={commissionMonth} onChange={selectMonth}>
        <option
          value={commissionMonth}
        >
          {commissionMonth}
        </option>
        {months.map(month => {
          return <option value={month}>{`${currentYear}-${month}-1`}</option>
        })}
      </select>
    )
  }

  const getCompany = () => {
    return (
      <select value={company} onChange={selectCompany}>
        <option value="CYBS" >CYBS</option>
        <option value="ANET">Anet</option>
      </select>
    )
  }

  const selectMonth = (event) => {
    const currentSelectedMonth = event.target.value
    const arrayValueOfMonth = months.indexOf(currentSelectedMonth)
    setCommissionMonth(`${currentYear}-${currentSelectedMonth}-1`)
    if (arrayValueOfMonth === 0) {
      return setCurrentMonth(`${currentYear - 1}-${months[11]}-1`)
    }
    return setCurrentMonth(`${currentYear}-${months[arrayValueOfMonth - 1]}-1`)
  }

  const selectCompany = (event) => {
    const currentCompany = event.target.value
    setCompany(currentCompany)
  }

  const setRate = (e) => {
    setConversionRate(e.target.value)
  }

  const download = company === 'CYBS' ? [headers.headers, ...downloadableFile] : [headers.anetHeaders, ...downloadableFile]
  return (
    <div>
      <div>
        {downloadableFile.length > 1 &&
          <>
            <CSVLink data={download} target="_blank" filename={`Monthly-Report.csv`}>
              Download
            </CSVLink>
          </>
        }
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {downloadableFile.length < 1 &&
          <div style={{ marginLeft: "30px" }}>
            CAD to USD @
            <input style={{ marginLeft: "10px" }} onChange={setRate} type="number" />
          </div>}
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          <p style={{ fontSize: '18px' }}>Select Commission Month {getMonth()} and {getCompany()} and file </p>
          <input
            type="file"
            onChange={getDocumentToSend}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </div>
      {downloadableFile.length > 1 &&
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              {makeTableHeader()}
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
          </Table>
        </div>
      }
    </div>
  )
}

export default Reports;