import React, { Component } from 'react'
import Auth0 from '../Auth0/Auth0'


export default class Callback extends Component {
  componentDidMount() {
    const auth = new Auth0()
    auth.handleAuthentication()
    
    
  }
  render() {
    return (
      <div>
        <div>
          Loading.....
        </div>
      </div>
    )
  }
}
