export const headers = [
  { label: 'Gateway ID', key: 'Gateway_ID' },
  { label: 'Company', key: 'Company' },
  { label: 'Full Name', key: 'Full_Name' },
  { label: 'Phone', key: 'Phone' },
  { label: 'Email', key: 'Email' },
  { label: 'City', key: 'City' },
  { label: 'Country', key: 'Country' },
  { label: 'Lead Source', key: 'Lead_Source' },
  { label: 'Last Activity', key: 'Last_Activity_Time' },
  { label: 'Lead Status', key: 'Lead_Status' },
  { label: 'Date Approved', key: 'Date_Approved' },
  // { label: 'Notes', key: 'Notes1' },
  { label: 'MCC', key: 'MCC' },
  { label: 'MID', key: 'MID' },
  { label: 'Sales Agent', key: 'Sales_Agent' },
  { label: "Account Executive", key: 'Account_Executive' },
];

export default headers;