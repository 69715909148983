import React, { Component } from 'react'
import { Motion, spring, presets } from 'react-motion';
import './modal.scss'

class LeadSuccess extends Component {





  render() {
    const {
      props: {
        display,
      }
    } = this

    return (
      <div >
        <Motion
          style={{

            modalOpacity: spring(display ? 1 : 0, presets.noWobble)
          }}
        >
          {({ modalOpacity }) => (
            <div
              className="modal-container"
              style={{
                opacity: modalOpacity,
                display: modalOpacity === 0 ? 'none' : undefined
              }}
            >
              <div
                ref={node => this.node = node}
                className="inner-modal-container-sending"
              >
                <h1>Lead submitted successfully </h1>
                <button
                  className='lead-success-btn'
                  onClick={this.props.closeModal}
                >OK</button>
              </div>
            </div>
          )}
        </Motion>
      </div>
    )
  }
}
export default LeadSuccess