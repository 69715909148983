import React, { Component } from 'react'
import './LandingPage.scss'
import axios from 'axios'
import createInputs from '../../utils/components/createInputs'


class LandingPage extends Component {

  state = {
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    Gateway: '',
    salesRep: '',
    accountExecutive: '',
    repEmail: '',
    subRep: '',
    notes: '',
    reason: ''

  }


  submit = () => {
    const firstName = this.state.firstName.trim()
    const lastName = this.state.lastName.trim()
    const businessName = this.state.businessName.trim()
    const email = this.state.email.trim()
    const phoneNumber = this.state.phoneNumber.trim()
    const Gateway = this.state.Gateway.trim()
    const salesRep = this.state.salesRep.trim()
    const repEmail = this.state.repEmail.trim()
    const subRep = this.state.subRep.trim()
    const accountExecutive = this.state.accountExecutive.trim()
    const notes = this.state.notes.trim()
    const reason = this.state.reason.trim()

    const newMerchant = {
      firstName: firstName,
      lastName: lastName,
      businessName: businessName,
      email: email,
      phoneNumber: phoneNumber,
      Gateway: Gateway,
      salesRep: salesRep,
      accountExecutive: accountExecutive,
      repEmail: repEmail,
      subRep: subRep,
      notes: notes,
      reason: reason,
      Lead_Source: 'Website'
    }

    axios.post('/newlead', newMerchant)
      .then(() => {

        this.setState({
          firstName: '',
          lastName: '',
          businessName: '',
          email: '',
          phoneNumber: '',
          Gateway: '',
          salesRep: '',
          accountExecutive: '',
          repEmail: '',
          subRep: '',
          notes: '',
          reason: ''
        })
      })
  }
  onChange = (e) => {
    const value = e.target.value
    this.setState({ [e.target.name]: value })
  }
  render() {

    const inputObject = [
      {
        title: 'First Name',
        name: 'firstName',
        type: 'input',
        stateValue: this.state.firstName,
        onEventChange: this.onChange
      },
      {
        title: 'Last Name',
        name: 'lastName',
        type: 'input',
        stateValue: this.state.lastName,
        onEventChange: this.onChange
      },
      {
        title: 'Business Name',
        name: 'businessName',
        type: 'input',
        stateValue: this.state.businessName,
        onEventChange: this.onChange
      },
      {
        title: 'Email Address ',
        name: 'email',
        type: 'input',
        stateValue: this.state.email,
        onEventChange: this.onChange
      },
      {
        title: 'Phone Number',
        name: 'phoneNumber',
        type: 'input',
        stateValue: this.state.phoneNumber,
        placeholder: '8888888888',
        onEventChange: this.onChange
      },
      {
        title: 'Notes',
        name: 'notes',
        type: 'textArea',
        placeholder: 'Notes go here',
        stateValue: this.state.notes,
        onEventChange: this.onChange,
      }
    ]


    return (
      <div className='landingPage-wrapper'>
        <div className='landingNav'>
          <img src={require('../Login/logo.png')} alt='' />
        </div>
        <div className='landingForm'>
          <h1 >New Merchant Submission</h1>
          <div className='form-fields'>
            <div className='left-form'>
              {inputObject.map(input => createInputs(input))}
            </div>
          </div>
          <div className='form-submit-btn'>
            <button onClick={this.submit}> Submit </button>
          </div>
        </div>
      </div>
    )
  }
}
export default LandingPage;