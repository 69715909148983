import React, { Component } from 'react'
import { Motion, spring, presets } from 'react-motion';
import axios from 'axios'
import './modal.scss'

class Modal extends Component {
  state = {
    gatewayId: '',
    salesRep: '',
    cyberRep: '',
    changeGWID: false,
    changeRep: false,
    changeAccountExec: false
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  handleClick = (e) => {
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }
    this.props.handleOutsideClick()
  }

  changeGatewayId = () => {
    this.setState({ changeGWID: true })
  }

  updateGateWay = (modalMerchant) => {
    const zohoID = modalMerchant[0].id
    const gatewayId = this.state.gatewayId
    const name = 'Gateway_ID'
    const input = {
      zohoID,
      gatewayId
    }
    if (gatewayId) {
      axios.put('updateGateway', input).then(
        this.props.refreshInfo(zohoID, name, gatewayId)
      )
    }
    this.setState({ changeGWID: false, gatewayId: '' })
  }

  changeSalesRep = () => {
    this.setState({ changeRep: !this.state.changeRep })
  }

  changeAccountExec = () => {
    this.setState({ changeAccountExec: !this.state.changeAccountExec })
  }

  updateSalesRep = (modalMerchant) => {
    const zohoID = modalMerchant[0].id
    const salesRep = this.state.salesRep
    const name = 'Sales_Agent'
    const input = {
      zohoID,
      salesRep
    }
    if (salesRep) {
      axios.put('/updateSalesRep', input).then(
        this.props.refreshInfo(zohoID, name, salesRep)
      )
    }
    this.setState({ change: false, cyberRep: '' })

  }

  updateAccountExec = (modalMerchant) => {
    const zohoID = modalMerchant[0]?.id
    const accountExe = this.state.cyberRep
    const name = 'Account_Executive'
    const input = {
      zohoID,
      accountExe
    }
    if (accountExe) {
      axios.put('/updateAccountExec', input).then(
        this.props.refreshInfo(zohoID, name, accountExe)
      )
    }
    this.setState({ changeAccountExec: false, cyberRep: '' })
  }


  handleChange = (e) => {
    const value = e.target.value
    this.setState({ [e.target.name]: value })
  }

  render() {
    const {
      props: {
        display,
        changeToggle,
        modalMerchant,
        user,
        cyberSource,
        handleOutsideClick
      },
      state: {
        changeGWID,
        changeRep,
        changeAccountExec,
      }
    } = this
    const pendToArray = modalMerchant[0].Open_UW_Pends ? modalMerchant[0].Open_UW_Pends.split(/\r?\n|\r|\n/g) : [];
    const noteToArray = modalMerchant[0].Notes1 ? modalMerchant[0].Notes1.split(/\r?\n|\r|\n/g) : [];

    const showPends = pendToArray.length > 0
    const notes = noteToArray.map(note => {
      return <p>{note}</p>
    })
    const pends = pendToArray.map(pends => {
      return <p>{pends}</p>
    })
    const salesReps = this.props.visa.concat(this.props.cyberSource)
    const makeSalesRepList = salesReps.map(salesRep => {
      return <option value={salesRep.name} > {salesRep.name}</option>
    })
    const accountExList = cyberSource.map(AE => {
      return <option value={AE.name} > {AE.name}</option>
    })

    return (
      <div >
        <Motion
          style={{
            modalOpacity: spring(display ? 1 : 0, presets.noWobble)
          }}

        >
          {({ modalOpacity }) => (
            <div
              className="modal-container"
              style={{
                opacity: modalOpacity,
                display: modalOpacity === 0 ? 'none' : undefined
              }}
            >
              <div
                ref={node => this.node = node}
                className="inner-modal-container"
              >
                <div className='modal-name' >
                  <h1>{modalMerchant[0].Company}</h1>
                </div>
                <div className='modal-content'>
                  <div className='content-row-1'>
                    <h3>Date Submitted: <p className='modal-content-info'>{modalMerchant[0].Created_Time.split('T', 1)[0]}</p></h3>
                    <h3>Last Updated: <p className='modal-content-info'>{
                      (modalMerchant[0].Last_Activity_Time &&
                        modalMerchant[0].Last_Activity_Time.split('T', 1)[0]
                      )
                    }</p></h3>
                    <h3>Status: <p className='modal-content-info'> {modalMerchant[0].Lead_Status} </p></h3>
                    <h3>GatewayID:<p className='modal-content-info'>{modalMerchant[0].Gateway_ID} </p>
                      <button onClick={this.changeGatewayId} className='edit-btn' >Edit</button>
                    </h3>
                    <h3>Merchant's Name:<p className='modal-content-info'>{modalMerchant[0].Full_Name}</p></h3>
                  </div>
                  <div className='content-row-2'>

                    {changeGWID && <span>
                      <input
                        className='rep-selector'
                        name='gatewayId'
                        value={this.state.gatewayId}
                        onChange={this.handleChange}
                      />
                      <button onClick={() => this.updateGateWay(modalMerchant)} className='update-btn' >update</button>
                    </span>}
                    <h3>Sales Rep:<p className='modal-content-info'>{modalMerchant[0].Sales_Agent}</p>
                      {(user === 'odeperio@visa.com' || user === 'shaunc@startmerchantservices.com' || user === 's_nostrom@hotmail.com') &&
                        <button onClick={this.changeSalesRep} className='edit-btn'>Edit</button>
                      }
                    </h3>
                    {changeRep &&
                      <div className='rep-selector'>
                        <select
                          name='salesRep'
                          value={this.state.salesRep}
                          onChange={this.handleChange}
                        >
                          <option value={this.state.subRep}>{this.state.subRep}</option>
                          {makeSalesRepList}
                        </select>
                        <button className='update-btn' onClick={() => this.updateAccountExec(modalMerchant)}>Update</button>
                      </div>
                    }

                    <h3>Account Exec:<p className='modal-content-info'>{modalMerchant[0].Account_Executive}</p>
                      {(user === 'odeperio@visa.com' || user === 'shaunc@startmerchantservices.com' || user === 's_nostrom@hotmail.com') &&
                        <button onClick={this.changeAccountExec} className='edit-btn'>Edit</button>
                      }
                    </h3>
                    {changeAccountExec &&
                      <div className='rep-selector'>
                        <select
                          name='cyberRep'
                          value={this.state.cyberRep}
                          onChange={this.handleChange}
                        >
                          <option value={this.state.subRep}>{this.state.subRep}</option>
                          {accountExList}
                        </select>
                        <button className='update-btn' onClick={() => this.updateAccountExec(modalMerchant)}>Update</button>
                      </div>
                    }
                    <h3>Submitted Rep:<p className='modal-content-info'>{modalMerchant[0].Submitting_Agent}</p></h3>
                    <h3>Start Rep:<p className='modal-content-info'>{modalMerchant[0].Account_Rep}</p> </h3>
                    <h3>Reason:<p className='modal-content-info'> {modalMerchant[0].Reason} </p></h3>
                  </div>
                </div>
                <h2>Notes</h2>
                <div className='content-notes'>
                  <div> {notes} </div>
                </div>
                {showPends &&
                  <div>
                    <h2>Open Pends</h2>
                    <div className='content-notes'>
                      <div> {pends} </div>
                    </div>
                  </div>
                }
                <div
                  className="exit-modal"
                  onClick={handleOutsideClick}> X
                </div>
              </div>
            </div>
          )}
        </Motion>
      </div>
    )
  }
}
export default Modal