import React, { Component } from 'react'
import './LandingPage.scss'
import Auth0 from '../Auth0/Auth0'
import axios from 'axios'
import _ from 'lodash'
import SendingModal from '../Modal/SendingModal'
import LeadSuccess from '../Modal/LeadSuccess'
import createInputs from '../../utils/components/createInputs'

class AnetLanding extends Component {


  componentDidMount = () => {
    axios.get('/api/getAllAgents')
      .then(res => {
        res.data.map(agent => {
          if (agent.company === 'Visa') {
            this.setState({ Visa: this.state.Visa.concat(agent) })
          }
          if (agent.company === 'CyberSource') {
            this.setState({ CyberSource: this.state.CyberSource.concat(agent) })
          }
          if (agent.company === 'CyberSourceSales') {
            this.setState({ CyberSourceSales: this.state.CyberSourceSales.concat(agent) })
          }
          return null
        })
      })
  }

  state = {
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    Gateway: '',
    company: 'Visa',
    salesRep: 'Select Sales Rep',
    accountExecutive: 'Select Account Executive',
    repEmail: '',
    subRep: '',
    notes: '',
    reason: '',
    projectedVolume: '',
    file: null,
    statement: null,
    Visa: [],
    CyberSource: [],
    CyberSourceSales: [],
    modalShow: false,
    modalSuccess: false,
    currency: 'United States',
    rateQuote: 'No'
  }

  getDocumentToSend = (event) => {
    const files = event.target.files[0]

    this.setState({ file: files })
  }

  getStatementToSend = (event) => {
    const files = event.target.files[0]
    this.setState({ statement: files })
  }

  closeModal = () => {
    this.setState({ modalSuccess: false })
  }



  selectOnChange = (event) => {
    const { company, Visa, CyberSourceSales } = this.state
    let searchCompany
    if (company === 'Visa') {
      searchCompany = Visa
    }
    if (company === 'CyberSource') {
      searchCompany = CyberSourceSales
    }

    if (event.target.value === 'none') {
      this.setState({ salesRep: '', repEmail: '' })
    } else {
      const value = event.target.value

      const findEmail = _.find(searchCompany, salesRep => salesRep.name === value)
      const email = findEmail.email
      this.setState({
        salesRep: value,
        repEmail: email,
        subRep: value
      })
    }
  }

  radioOnChange = (event) => {
    let value = event.target.value
    if (value === 'Authorize.net') {
      value = 'Visa'
    }
    this.setState({
      [event.target.name]: value,
      salesRep: 'Select Sales Rep',
      accountExecutive: 'Select Account Executive',
      subRep: ''
    })
  }

  radioCurrencyOnChange = (event) => {
    const value = event.target.value
    this.setState({ currency: value })
  }

  radioChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  changeAccountExecutive = (event) => {
    if (event.target.value === 'none') {
      this.setState({ accountExecutive: '' })
    } else {
      const value = event.target.value
      const findEmail = _.find(this.state.CyberSource, salesRep => salesRep.name === value)
      const email = findEmail.email
      this.setState({
        salesRep: value,
        repEmail: email,
        accountExecutive: value
      })
    }
  }


  submit = () => {
    const firstName = this.state.firstName.trim()
    const lastName = this.state.lastName.trim()
    const businessName = this.state.businessName.trim()
    const email = this.state.email.trim()
    const phoneNumber = this.state.phoneNumber.trim()
    const Gateway = this.state.Gateway.trim()
    const salesRep = this.state.salesRep.trim()
    const repEmail = this.state.repEmail.trim()
    const subRep = this.state.subRep.trim()
    const accountExecutive = this.state.accountExecutive.trim()
    const notes = this.state.notes.trim()
    const reason = this.state.reason.trim()
    const company = this.state.company
    const currency = this.state.currency
    const needsQuote = this.state.rateQuote
    const data = new FormData()
    const projectedVolume = this.state.projectedVolume.trim()
    data.append('file', this.state.file)
    data.append('file', this.state.statement)

    this.setState({ modalShow: true })
    axios.post('/newlead', data, {
      params: {
        firstName: firstName,
        lastName: lastName,
        businessName: businessName,
        email: email,
        phoneNumber: phoneNumber,
        Gateway: Gateway,
        salesRep: salesRep,
        accountExecutive: accountExecutive,
        repEmail: repEmail,
        subRep: subRep,
        notes: notes,
        reason: reason,
        Lead_Source: company,
        projectedVolume: projectedVolume,
        currency: currency,
        needsQuote,
      },

    }).then((res) => {
      this.setState({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phoneNumber: '',
        Gateway: '',
        salesRep: '',
        accountExecutive: '',
        repEmail: '',
        subRep: '',
        notes: '',
        reason: '',
        projectedVolume: '',
        modalShow: false,
        modalSuccess: true,
        file: null,
        statement: null
      })
      this.refs.file.value = ''
      this.refs.file1.value = ''
    })
  }

  onChange = (e) => {
    const value = e.target.value
    this.setState({ [e.target.name]: value })
  }

  render() {
    const Auth = new Auth0()
    const { Visa, CyberSource, CyberSourceSales, company } = this.state

    const inputObject = [
      {
        title: 'First Name',
        name: 'firstName',
        type: 'input',
        stateValue: this.state.firstName,
        onEventChange: this.onChange
      },
      {
        title: 'Last Name',
        name: 'lastName',
        type: 'input',
        stateValue: this.state.lastName,
        onEventChange: this.onChange
      },
      {
        title: 'Business Name',
        name: 'businessName',
        type: 'input',
        stateValue: this.state.businessName,
        onEventChange: this.onChange
      },
      {
        title: 'Email Address ',
        name: 'email',
        type: 'input',
        stateValue: this.state.email,
        onEventChange: this.onChange
      },
      {
        title: 'Phone Number',
        name: 'phoneNumber',
        type: 'input',
        stateValue: this.state.phoneNumber,
        placeholder: '8888888888',
        onEventChange: this.onChange
      },
      {
        title: 'Gateway ID',
        name: 'Gateway',
        type: 'input',
        stateValue: this.state.Gateway,
        onEventChange: this.onChange
      },
      {
        title: 'Company',
        name: 'company',
        type: 'radio',
        checked: this.state.company,
        stateValue: this.state.Gateway,
        onEventChange: this.radioOnChange,
        option1: 'Visa',
        option2: 'CyberSource',
      },
      {
        title: 'Sales Representative ',
        name: 'salesRep',
        type: 'select',
        stateValue: this.state.salesRep,
        onEventChange: this.selectOnChange,
        salesReps: (company === 'Visa' ? Visa : CyberSourceSales)
      },
      {
        title: 'Account Executive',
        name: 'accountExecutive',
        type: 'select',
        stateValue: this.state.accountExecutive,
        onEventChange: (company === 'Visa' ? this.onChange : this.changeAccountExecutive),
        salesReps: CyberSource
      },
      (company === 'Visa' ? {
        title: 'Submitting Rep',
        name: 'subRep',
        type: 'select',
        stateValue: this.state.subRep,
        onEventChange: this.onChange,
        salesReps: Visa,
      } : {
        title: 'Annual Volume',
        name: 'projectedVolume',
        type: 'input',
        stateValue: this.state.projectedVolume,
        onEventChange: this.onChange,
      }),
      {
        title: 'Documents',
        type: 'file',
        onEventChange: this.getDocumentToSend,
        ref: 'file'
      },
      {
        title: 'Statement',
        type: 'file',
        onEventChange: this.getStatementToSend,
        ref: 'file1'
      },
      {
        title: 'Location',
        name: 'currency',
        type: 'radio',
        checked: this.state.currency,
        stateValue: this.state.Gateway,
        onEventChange: this.radioCurrencyOnChange,
        option1: 'United States',
        option2: 'Canada',
      },
      {
        title: '',
        name: 'currency',
        type: 'radio',
        checked: this.state.currency,
        stateValue: this.state.Gateway,
        onEventChange: this.radioCurrencyOnChange,
        option1: 'Australia',
        option2: 'Puerto Rico/USVI/Guam',
      },
      {
        title: 'Reason',
        name: 'reason',
        type: 'input',
        stateValue: this.state.reason,
        onEventChange: this.onChange
      },
      {
        title: 'Needs Rate Quote',
        name: 'rateQuote',
        type: 'radio',
        checked: this.state.rateQuote,
        stateValue: this.state.rateQuote,
        onEventChange: this.radioChange,
        option1: 'No',
        option2: 'Yes'
      },
      {
        title: 'Notes',
        name: 'notes',
        type: 'textArea',
        placeholder: 'Notes go here',
        stateValue: this.state.notes,
        onEventChange: this.onChange,
      }
    ];

    return (
      <div className='landingPage-wrapper'>
        <div className='landingNav'>
          <img src={require('../Login/logo.png')} alt='' />
          <h1 className='landing-login-button' onClick={Auth.login}>Login</h1>
        </div>
        <div className='landingForm'>
          <h1 >New Merchant Submission</h1>
          <div className='form-fields'>
            <div className='left-form'>
              {inputObject.map(input => createInputs(input))}
            </div>
          </div>
          <div className='form-submit-btn'>

            <button onClick={this.submit}> Submit </button>
          </div>
        </div>
        {(
          this.state.modalShow ?
            <SendingModal
              display={this.state.modalShow}
            />
            :
            null
        )}
        {(
          this.state.modalSuccess ?
            <LeadSuccess
              display={this.state.modalSuccess}
              closeModal={this.closeModal}
            />
            :
            null
        )}
      </div>
    )
  }
}
export default AnetLanding;
