import React, { Component } from 'react'
import './Navbar.scss'
import Auth0 from '../Auth0/Auth0'
import { CSVLink } from "react-csv";

import headers from './headers'



class Navbar extends Component {
  state = {
    Menu: false,
    searchValue: null,
    typeOfSearch: 'Gateway Id',
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  handleClick = (e) => {
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleOutsideClick()
  }

  search = ({ target: { value } }) => {
    this.setState({ searchValue: value })
  }

  searchType = ({ target: { value } }) => {
    this.setState({ typeOfSearch: value })
  }

  startSearch = () => {
    const { searchValue, typeOfSearch } = this.state
    const { search } = this.props
    search(1, null, searchValue, typeOfSearch)
  }
  clearSearch = () => {
    const { search } = this.props
    this.setState({ searchValue: '', typeOfSearch: 'Gateway Id' })
    search(1, null, '', '')
  }

  makeOptions = (options) => {
    return options.map(option => {
      return <option value={option}> {option}</option>
    })
  }

  handleOutsideClick = () => {
    this.setState({ Menu: false })
  }
  showMenu = () => {
    this.setState({ Menu: !this.state.Menu })
  }
  incentive = () => {
    this.props.page('/dashboard/incentive')
  }
  agents = () => {
    this.props.page('/dashboard/agents')
  }
  reports = () => {
    this.props.page('/dashboard/reports')
  }

  render() {
    const { typeOfSearch } = this.state
    const { company, zohoList, admin } = this.props
    const csvList = zohoList.filter(i => i.Lead_Source === company)
    const auth = new Auth0()
    const searchTypeOptions = [
      'Gateway Id',
      'Company',
      'Email'
    ]
    return (
      <div className='Navbar-wrapper' >
        <div className='Nav-search'>
          <img src={require('../Login/logo.png')} alt='' />
          <h2>Search</h2>
          <input
            className='Nav-input'
            name='search'
            onChange={this.search}
          />
          <h2>By</h2>
          <div className="search-by">
            <select
              value={typeOfSearch}
              onChange={this.searchType}
            >
              {/* <option value={typeOfSearch}>{typeOfSearch} </option> */}
              {this.makeOptions(searchTypeOptions)}
            </select>
          </div>
          <button
            onClick={this.startSearch}
            className="search-button">
            Submit
          </button>
          <button
            onClick={this.clearSearch}
            className="search-button"
          >
            Clear
          </button>
        </div>
        <h2 className='incentive-btn' onClick={this.incentive}>Incentive Program</h2>
        <div ref={node => this.node = node} className={`Nav-menu ${this.state.Menu ? 'active' : ''}`} >
          <h2 onClick={this.showMenu}> Menu </h2>
          {this.state.Menu ? (
            <ul >
              <li onClick={this.agents}>Agents</li>
              <li onClick={auth.logout}>Logout</li>
              <li>
                <CSVLink data={csvList} headers={headers}>
                  CSV
                </CSVLink>
              </li>
              {admin &&
                <li onClick={this.reports}>
                  Report
                </li>
              }
            </ul>
          ) : (null)}
        </div>
      </div>
    )
  }
}
export default Navbar