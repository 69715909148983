import React, { Component } from 'react'
import './TopNav.scss'


class TopNav extends Component {

  state = {
    Menu: false,
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  componentWillUnmount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }
  handleClick = (e) => {
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleOutsideClick()
  }

  handleOutsideClick = () => {
    this.setState({ Menu: false })
  }
  showMenu = () => {
    this.setState({ Menu: !this.state.Menu })
  }
  home = () => {
    this.props.page('/dashboard/')
  }
  agents = () => {
    this.props.page('/dashboard/agents')
  }
  logout = () => {
    this.props.page('/')
  }

  render() {
    return (
      <div className='Navbar-wrapper' >
        <div className='Nav-search'>
          <img src={require('../Login/logo.png')} alt='' />

        </div>
        <div ref={node => this.node = node} className={`Nav-menu ${this.state.Menu ? 'active' : ''}`} >
          <h2 className='topnav-menu-items' onClick={this.home}> Home </h2>
          {/* {this.state.Menu ? (
            <ul >
              <li onClick={this.home}>Home</li>
              <li onClick={this.agents}>Agents</li>
              <li onClick={this.logout}>Logout</li>
            </ul>
          ) : (null)} */}
        </div>
      </div>
    )
  }
}
export default TopNav;