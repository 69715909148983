import React, { Component } from 'react';
import TopNav from '../TopNav/TopNav';
import './SalesAgentsInfo.scss'
import { getAllAgents } from '../../api/dashBoardApi'
import axios from 'axios'

class SalesAgentsInfo extends Component {
  state = {
    name: '',
    email: '',
    fullList: [],
    companyToSet: '',
    agentType: 'Sales Agent',
    newCompany: false
  }

  componentDidMount = () => {

    this.getSalesAgents()
  }

  getSalesAgents = async () => {
    const { currentCompany: company, admin } = this.props
    if (admin) {
      const { data } = await getAllAgents()
      const companies = data.map(agents => agents.company)
      const filterCompanies = [...new Set(companies)];
      const defaultCompany = filterCompanies.filter(company => company !== 'cyberSourceSales')
      this.setState({ fullList: filterCompanies, companyToSet: defaultCompany[0] })

      filterCompanies.forEach(company => {
        this.setState({ [company]: [] })
        data.map(agent => {
          if (agent.company === company) {
            this.setState({ [company]: this.state[company].concat(agent) })
          }
          return null;
        })
      })
      return;
    }
    axios.post(`/api/getSalesAgents/${company} `)
      .then(res => {
        const companies = res.data.map(agents => agents.company)
        const filterCompanies = [...new Set(companies)];
        this.setState({ fullList: filterCompanies })
        filterCompanies.forEach(company => {
          this.setState({ [company]: [] })
          res.data.map(agent => {
            if (agent.company === company) {
              this.setState({ [company]: this.state[company].concat(agent) })
            }
            return null;
          })
        })
      })
  }

  deleteAgent = (agent, company) => {
    axios.post(`/api/deleteAgent/${agent}/${company}`)
      .then(() => {
        this.getSalesAgents()
      })
  }


  showSalesAgents = (company) => {
    const agentList = this.state[company]
    const title = company === "CyberSource" ? 'Current Account Executives' : 'Current Sales Agents'

    return (
      <div className="agent-table">
        {company.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
        <h2>{title}</h2>
        <table>
          <thead >
            <tr>
              <td>Name</td>
              <td>Email</td>
            </tr>
          </thead>
          <tbody>
            {agentList && agentList.map(agent => {
              return (
                <tr>
                  <td>{agent.name}</td>
                  <td>{agent.email}</td>
                  <td><button onClick={() => this.deleteAgent(agent.name, company)}>Delete</button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  onSubmit = () => {
    const { name, email, companyToSet, agentType } = this.state
    const { currentCompany } = this.props
    let company = companyToSet || currentCompany;
    if (company === 'CyberSource' && agentType === 'Sales Agent') {
      company = 'CyberSourceSales'
    }

    const newAgent = {
      name: name,
      email: email
    }
    if (newAgent.name === '') return;
    axios.post(`/api/newAgent/${company}`, newAgent)
      .then(() => {
        this.getSalesAgents()
        this.setState({
          name: '',
          email: '',
          companyToSet: ''
        })
      })
  }

  onChange = (e) => {
    const value = e.target.value
    this.setState({ [e.target.name]: value })
  }
  changeCompany = ({ target: { value } }) => {
    const company = value;
    this.setState({ companyToSet: company })
  }

  agentType = ({ target: { value } }) => {
    const type = value
    this.setState({ agentType: type })
  }

  newCompany = ({ target: { value } }) => {
    const company = value;
    this.setState({ companyToSet: company })
  }

  setNewCompany = () => {
    this.setState({ newCompany: !this.state.newCompany })
  }

  render() {
    const { fullList, companyToSet, newCompany } = this.state
    const { currentCompany, admin } = this.props
    const filterCompany = fullList.filter(company => company !== 'CyberSourceSales')


    return (
      <div className='agent-wrapper'>
        <TopNav page={this.props.page} />
        <div className='agent-body'>
          <h1>New Agent</h1>
          {admin &&
            <div>
              <button onClick={this.setNewCompany}>{(newCompany ? 'Current Company' : 'New Company')} </button>
            </div>
          }
          <h4 className='newAgentInput'>Name <input value={this.state.name} name='name' onChange={this.onChange} /></h4>
          <h4 className='newAgentInput'>Email  <input value={this.state.email} name='email' onChange={this.onChange} /></h4>
          {
            admin && !newCompany &&
            <div className='agent-options'>
              <h4>Company</h4>
              <select onChange={this.changeCompany} value={companyToSet}>
                {filterCompany.map(company => {
                  return (
                    <option>{company}</option>
                  )
                })}
              </select>
            </div>
          }

          {
            admin && newCompany &&
            <div className='agent-options'>
              <h4>New Company</h4>
              <input onChange={this.newCompany} />
            </div>
          }
          {currentCompany === "CyberSource" || companyToSet === 'CyberSource' ?
            <div className='agent-options'>
              <h4>Agent Type</h4>
              <select onChange={this.agentType}>
                <option>
                  Account Executive
                </option>
                <option>
                  Sales Agent
                </option>
              </select>
            </div>
            : null
          }
          <button onClick={this.onSubmit}> Submit</button>
          <div className="agent-table-wrapper">
            {
              (fullList &&
                fullList.map((agents) => {
                  return this.showSalesAgents(agents)
                }))
            }
          </div>
        </div>
      </div>
    )
  }
}
export default SalesAgentsInfo