import React, { Component } from 'react'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import Modal from '../Modal/Modal'
import axios from 'axios';

import './MainView.scss';



class MainView extends Component {
  state = {
    zohoList: [],
    pageList: [1],
    moreRecords: null,
    currentPage: 1,
    ModalMerchant: null,
    displayModal: false,
    repFilter: '',
    filterByName: '',
    filterByArrow: '',
    originalList: [],
    showStatusSelect: false
  }

  goToNewPage = async (newPage, agent, searchValue, searchType, company) => {
    const { moreRecords, currentPage, repFilter } = this.state
    const body = {
      company: company || this.props.company,
      page: newPage,
      agent: agent || repFilter,
      searchValue,
      searchType
    }

    if (searchType && searchValue) {
      this.setState({ repFilter: '' })
      body.agent = ''
    }

    const { data: { data: zohoList, info } } = await axios.post('/leads', body)
    if (moreRecords && (currentPage < newPage)) {
      this.setState({ zohoList, moreRecords: info.more_records, currentPage: info.page })
      this.setState({ pageList: this.state.pageList.concat(info.page + 1) })
    } else {
      this.setState({ zohoList, moreRecords: info.more_records, currentPage: info.page })
    }
  }

  componentDidMount = async () => {
    const body = {
      company: this.props.company,
      page: 1
    }
    const { data: { data: zohoList, info } } = await axios.post('/leads', body)
    const original = zohoList.map(item => item)
    this.setState({ zohoList, moreRecords: info?.more_records, currentPage: info?.page, originalList: original })
    if (this.state.moreRecords) {
      this.setState({ pageList: [1, 2] })
    }
  }

  changeToggle = (company) => {
    this.setState({
      displayModal: !this.state.displayModal
    })
    this.setState({ ModalMerchant: [company] })
  }

  handleOutsideClick = () => {
    this.setState({ displayModal: false })
  }

  searchedList = (i, c) => {
    if (!i) return
    const dateCreated = i ? i.Created_Time.split('T', 1)[0] : ''
    const salesAgent = i && i.Sales_Agent !== 'Select Sales Rep' ? i.Sales_Agent : null
    const accountExec = i && i.Account_Executive !== 'Select Account Executive' ? i.Account_Executive : null

    return (
      <tr className={(c % 2 === 0 ? 'grey' : 'white')} onClick={() => this.changeToggle(i)}>
        <td className='table-data-dates'>{dateCreated} </td>
        <td className='table-data-dates'>{i.Date_Approved}</td>
        <td className='main-table-data-gateway'>{i.Gateway_ID} </td>
        <td id='main-table-data'>{i.Company} </td>
        <td id='main-table-data'>{salesAgent}</td>
        <td id='main-table-data'>{accountExec}</td>
        <td id='main-table-data' className={
          (i.Lead_Status === 'Approved' ? 'statusApproved' :
            i.Lead_Status === 'Contacted/Have not Completed App' ? 'statusPending' : i.Lead_Status === 'New Lead' ? 'statusPending' :
              i.Lead_Status === 'Closing' ? 'statusPending' : i.Lead_Status === 'Underwriting' ? 'statusPending' :
                i.Lead_Status === 'Not yet Interested' ? 'statusPending' :
                  i.Lead_Status === 'Dead End' ? 'statusDeclined' : i.Lead_Status === 'Merchant Cancelled' ? 'statusDeclined' :
                    i.Lead_Status === 'Declined' ? 'statusDeclined' :
                      i.Lead_Status === 'New' ? 'statusNew' : null)}>
          {i.Lead_Status} </td>
      </tr>
    )
  }

  pagination = () => {
    const { pageList, currentPage } = this.state
    let newList = [...pageList]
    if (newList)

      if (newList[1] > currentPage) {
        if (currentPage === 1) {
          newList = [currentPage, currentPage + 1]
        }
        newList = [(currentPage - 1 === 0 ? null : currentPage - 1), currentPage, currentPage + 1]
        this.setState({ pageList: newList })
      }

    if (newList.length > 3) {
      newList = [currentPage - 1, currentPage, currentPage + 1]
      this.setState({ pageList: newList })
    }


    return pageList.map(page => {
      return (
        <div
          onClick={() => this.goToNewPage(page)}
          className={page === currentPage ? 'current-page' : null}
        >
          {page}
        </div>
      )
    })
  }

  handleFilter = (e) => {
    let agent;
    if (e.target.checked) {
      agent = e.target.value
      this.setState({ repFilter: agent })
      this.goToNewPage(1, agent)
    } else {
      agent = ''
      this.setState({ repFilter: agent })
      this.goToNewPage(1, '')
    }
  }

  setArrow = (name) => {
    const { filterByName, filterByArrow } = this.state;
    if (name === 'changed') {
      return this.setState({ filterByArrow: 'up' })
    }
    if (filterByArrow === '') {
      return this.setState({ filterByArrow: 'up' })
    }
    if (filterByName === name && filterByArrow === 'up') {

      return this.setState({ filterByArrow: 'down' })
    }
    if (filterByName === name && filterByArrow === 'down') {
      return this.setState({ filterByArrow: 'up' })
    }
  }

  filter = (type) => {
    const { zohoList, filterByArrow, filterByName } = this.state;
    if (filterByName !== type && filterByName !== '') {
      const filterDate = zohoList.sort((a, b) => {
        return new Date(b[type]) - new Date(a[type])
      })
      this.setState({ filterByName: type },
        this.setArrow('changed'),
        this.setState({ zohoList: filterDate })
      )
      return
    }
    if ((filterByArrow === '') || (filterByArrow === 'down')) {
      const filterDate = zohoList.sort((a, b) => {
        return new Date(b[type]) - new Date(a[type])
      })
      this.setState({ filterByName: type },
        this.setArrow(type),
        this.setState({ zohoList: filterDate })
      )
    }
    if (filterByArrow === 'up' && filterByName === type) {
      const filterDate = zohoList.sort((a, b) => {
        return new Date(a[type]) - new Date(b[type])
      })
      this.setState({ filterByName: type },
        this.setArrow(type),
        this.setState({ zohoList: filterDate })
      )
    }
  }

  arrowClass = (name) => {
    const { filterByName, filterByArrow } = this.state;
    if (name === filterByName && filterByArrow === 'up') {
      return 'date-arrow'
    }
    if (name === filterByName) {
      if (filterByArrow === 'down') {
        return 'date-arrow down'
      }
      return 'date-arrow'
    }
    return 'date-arrow hide'
  }

  sortByStatus = async ({ target: { value } }) => {
    const { originalList } = this.state;
    if (value === 'reset') {
      const clean = originalList.map(item => item)
      this.setState({ zohoList: clean, showStatusSelect: false, filterByArrow: '', filterByName: '' })
      return
    }
    const statusFilter = originalList.filter(lead => lead.Lead_Status === value)
    this.setState({ zohoList: statusFilter, filterByArrow: '', filterByName: '' })
  }

  render() {
    const { zohoList, moreRecords, ModalMerchant, displayModal, pageList, showStatusSelect } = this.state;

    return (
      <div>
        <Navbar
          page={this.props.page}
          onChange={this.props.onChange}
          makeList={this.props.makeList}
          zohoList={this.props.zohoList}
          company={this.props.company}
          admin={this.props.admin}
          search={this.goToNewPage}
        />
        <div className="component-wrapper">
          <Sidebar
            rep={this.props.rep}
            repFilter={this.state.repFilter}
            handleFilter={this.handleFilter}
            getMonth={this.props.getMonth}
            changeCompany={this.props.changeCompany}
            admin={this.props.admin}
            company={this.props.company}
            getNewPage={this.goToNewPage}
          />
          <div className='dashboard-wrapper'>

            {moreRecords &&
              <div className="pagination">
                {
                  pageList.length >= 3 && pageList[0] !== 1 && pageList[0] !== null && <div onClick={() => this.goToNewPage(1)}> {'<<'} </div>
                }
                {this.pagination()}
              </div>
            }
            <div className="main-table-wrapper">
              <table cellSpacing="0" >
                <thead >
                  <tr className='table-header-wrapper'>
                    <td onClick={() => this.filter('Created_Time')} >
                      <div className='table-dates'>
                        <div>
                          Date Submitted
                        </div>
                        <div className={this.arrowClass('Created_Time')}>^</div>
                      </div>
                    </td>
                    <td onClick={() => this.filter('Date_Approved')}>
                      <div className='table-dates'>
                        <div>
                          Date Approved
                        </div>
                        <div className={this.arrowClass('Date_Approved')}>
                          ^
                        </div>
                      </div>
                    </td>
                    <td>GW ID</td>
                    <td>Business Name</td>
                    <td>Sales Rep</td>
                    <td>Account Ex</td>
                    <td className='status-select'>
                      <div>
                        <div onClick={() => this.setState({ showStatusSelect: !showStatusSelect })}>
                          Status
                        </div>
                        {showStatusSelect &&
                          <div >
                            <select onChange={this.sortByStatus} >
                              <option value='reset'>All</option>
                              <option value='New'>
                                New
                              </option>
                              <option value='Approved'>
                                Approved
                              </option>
                              <option value='Declined'>
                                Declined
                              </option>
                              <option value='Closing'>
                                Closing
                              </option>
                            </select>
                          </div>
                        }
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody className="main-body-wrapper">
                  {
                    zohoList.map((i, c) => {
                      return this.searchedList(i, c)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {(displayModal ?
          <Modal
            handleOutsideClick={this.handleOutsideClick}
            display={displayModal}
            changeToggle={this.props.changeToggle}
            modalMerchant={ModalMerchant}
            company={this.props.company}
            visa={this.props.visa}
            cyberSource={this.props.cyberSource}
            user={this.props.user}
            refreshInfo={this.props.refreshInfo}
          />
          :
          null)}
      </div>
    )
  }
}
export default MainView