import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from './components/Dashboard/Dashboard';
import LandingPage from './components/LandingPage/LandingPage'
import AnetLanding from './components/LandingPage/AnetLanding'
import BigComm from './components/LandingPage/BigComm'
import Preferred from './components/LandingPage/Preferred'
import Ess from './components/LandingPage/Ess'
import Fms from './components/LandingPage/Fms'
import Rog from './components/LandingPage/Rog'
import Rrs from './components/LandingPage/Rrs'
import Zen from './components/LandingPage/Zen'
import VetVet from './components/LandingPage/VetVet';
import AfterNorth from './components/LandingPage/AfterNorth';
import Callback from './components/Callback/Callback'
import Auth0 from './components/Auth0/Auth0'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AnchorGroup from './components/LandingPage/AnchorGroup';
import Busca from './components/LandingPage/Busca';
import DarGroup from './components/LandingPage/DarGroup';
import Payspaze from './components/LandingPage/Payspaze';
import AppVision from './components/LandingPage/AppVision';
import leoNetwork from './components/LandingPage/leoNetwork';

class App extends Component {

  render() {
    const auth = new Auth0()

    return (
      <div className="App">

        <Router>
          <Switch>
            <Route path='/anet' component={AnetLanding} />
            <Route path='/bigcommerce' component={BigComm} />
            <Route path='/preferred' component={Preferred} />
            <Route path='/ess' component={Ess} />
            <Route path='/fms' component={Fms} />
            <Route path='/rog' component={Rog} />
            <Route path='/vb360' component={Rrs} />
            <Route path='/zen' component={Zen} />
            <Route path='/vetvet' component={VetVet} />
            <Route path='/af' component={AfterNorth} />
            <Route path='/auth/callback' component={Callback} />
            <Route path='/agrp' component={AnchorGroup} />
            <Route path='/busca' component={Busca} />
            <Route path='/dar' component={DarGroup} />
            <Route path='/pay' component={Payspaze} />
            <Route path='/av' component={AppVision} />
            <Route path='/leo' component={leoNetwork} />
            {/* <Route path='/dashboard' render={(props) => <Dashboard {...props} />} /> */}

            {
              auth.isAuthenticated() ?
                <Route path='/dashboard' render={(props) => <Dashboard {...props} />} />
                :
                <Route path='/home' component={LandingPage} />
            }
            <Route path='/' component={LandingPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
