import axios from 'axios';

export const getLeadInfo = async (mid) => {

  const options = {
    mid: mid
  }
  const { data } = await axios.post('/api/report/sendZoho', options)
  if (data && data.data.length > 0) {
    return data.data[0]
  }
}
export const updateZohoLead = async () => {
  const options = {

  }
  const { data } = await axios.post('/api/report/updateLead', options)
}