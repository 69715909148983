import React, { Component } from 'react';
import './Dashboard.scss'
import { Route, Switch } from "react-router-dom";
import MainView from '../MainView/MainView';
import Incentive from '../Incentive/Incentive';
import SalesAgentsInfo from '../SalesAgentsInfo/SalesAgentsInfo';
import Reports from '../Reports/Reports'
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { getAllAgents } from '../../api/dashBoardApi'
import { getUserInfo } from '../../api/user'

class Dashboard extends Component {
  state = {
    zohoList: [],
    user: '',
    search: '',
    repFilter: '',
    searched: [],
    month: '',
    displayModal: false,
    ModalMerchant: [],
    incentiveList: [],
    SalesAgents: [],
    currentCompany: '',
    Visa: [],
    CyberSource: [],
    fullUser: {}
  }




  handleOutsideClick = () => {
    this.setState({ displayModal: false })
  }
  componentDidMount = async () => {
    let date = new Date()
    let month = date.getMonth() + 1
    let checkMonth = (month < 10 ? `0${month}` : month)
    let year = date.getFullYear()
    let currentMonth = `${year}-${checkMonth}`
    const userInfo = localStorage.id_token ? jwt_decode(localStorage.id_token) : {
      name: ''
    }

    const userData = await getUserInfo(userInfo.name)

    const user = userInfo ? userInfo.name : null

    this.setState({
      currentMonth: currentMonth,
      currentCompany: userData.company,
      user: user,
      fullUser: userData
    })

    const { data } = await getAllAgents()
    data.map(agent => {
      if (agent.company === userData.company) {
        this.setState({ SalesAgents: this.state.SalesAgents.concat(agent.name) })
      }
      if (agent.company === 'Visa') {
        this.setState({ Visa: this.state.Visa.concat(agent) })
      } if (agent.company === 'CyberSource') {
        this.setState({ CyberSource: this.state.CyberSource.concat(agent) })
      }
      return null
    })
  }

  changeCompany = async (value) => {
    const { fullUser } = this.state
    if (fullUser.type === 'Admin') {
      this.setState({ currentCompany: value })
      const { data } = await getAllAgents()
      this.setState({
        Visa: [],
        CyberSource: [],
        SalesAgents: [],
      })
      data.map(agent => {
        if (agent.company === value) {
          this.setState({ SalesAgents: this.state.SalesAgents.concat(agent.name) })
        }
        if (agent.company === 'Visa') {
          this.setState({ Visa: this.state.Visa.concat(agent) })
        } if (agent.company === 'CyberSource') {
          this.setState({ CyberSource: this.state.CyberSource.concat(agent) })
        }
        return null
      })
    }
  }


  changeToggle = (id) => {
    this.setState({
      displayModal: !this.state.displayModal
    })
    const merchantModal = this.state.zohoList.filter(i => {
      return (i.id === null ? null : i.id.indexOf(id) !== -1)
    })

    this.setState({ ModalMerchant: merchantModal })

  }

  onChange = e => {
    this.setState({ search: e.target.value })
  }
  handleFilter = (e) => {
    if (e.target.checked) {
      this.setState({ repFilter: e.target.value })
    } else {
      this.setState({ repFilter: '' })
    }
  }
  getMonth = (currentMonth) => {
    this.setState({ month: currentMonth })
  }
  getCount = (rep) => {
    let count = 0
    this.state.zohoList.forEach(i => {
      if (i.Date_Approved) {
        if (i.Lead_Status === 'Approved' && i.Sales_Agent === rep && i.Date_Approved.split('-', 2).join('-') === this.state.currentMonth) {
          count += 1
        }
      }
    })
    return count
  }

  makeList = () => {
    const reps = this.state.zohoList.map(i => (i.Sales_Agent ? i.Sales_Agent : null));
    const allReps = [...new Set(reps.sort())];
    const list = []

    allReps.forEach(rep => {
      list.push({ name: rep, count: this.getCount(rep) })
    })

    const finallist = list.sort((a, b) =>
      (a.count > b.count) ? -1 : (a.count === b.count) ? 1 : 1
    )
    this.setState({ incentiveList: finallist })

  }

  refreshInfo = (id, name, value) => {
    const findItem = this.state.zohoList ? this.state.zohoList.find(item => item.id === id) : null
    if (findItem) {
      findItem[name] = value
    }
  }



  render() {
    const { search, SalesAgents, repFilter, user, fullUser } = this.state;
    const filterMonth = this.state.zohoList.filter(i => {
      const filterByMonth = (this.state.month === '' ? this.state.zohoList :
        i.Created_Time.split('-', 2).join('-').indexOf(this.state.currentMonth) !== -1)

      return filterByMonth
    })

    const admin = fullUser.type === 'Admin'

    const filterListByRep = filterMonth.filter(salesRep => {
      const selectedRep = repFilter.toLowerCase().trim()
      const repInArray = (selectedRep === '' ? filterMonth :
        salesRep.Sales_Agent === null ? null :
          salesRep.Sales_Agent.toLowerCase().indexOf(selectedRep) !== -1)

      return repInArray
    });


    const searchedList = filterListByRep.filter(i => {
      const lowerSearch = search.toLowerCase().trim();
      const isInStatus = (!i.Lead_Status ? null : i.Lead_Status.toLowerCase().indexOf(lowerSearch) !== -1)
      const isInGwid = (!i.Gateway_ID ? null : i.Gateway_ID.indexOf(lowerSearch) !== -1);
      const isInRep = (!i.Sales_Agent ? '' : i.Sales_Agent.toLowerCase().indexOf(lowerSearch) !== -1)
      const isInCompany = (!i.Company ? null : i.Company.toLowerCase().indexOf(lowerSearch) !== -1)
      const isInDateApproved = i.Created_Time.toLowerCase().indexOf(lowerSearch) !== -1;

      return isInStatus || isInGwid || isInRep || isInCompany || isInDateApproved;
    })

    return (
      <div>
        <Switch>
          <Route path='/dashboard/incentive' render={(props) => <Incentive {...props}
            page={this.props.history.push}
            allReps={SalesAgents}
            currentMonth={this.state.month}
            finalList={this.state.incentiveList}
            list={this.state.list}
            currentCompany={this.state.currentCompany}
          />} />
          {(
            this.state.currentCompany ?
              <Route path='/dashboard/agents' render={(props) => <SalesAgentsInfo {...props}
                currentCompany={this.state.currentCompany}
                page={this.props.history.push}
                admin={admin}
              />} /> :
              null
          )}
          {(
            user &&
            <Route exact path='/dashboard/' render={(props) => <MainView {...props}
              rep={SalesAgents}
              repFilter={this.state.repFilter}
              changeToggle={this.changeToggle}
              handleFilter={this.handleFilter}
              getMonth={this.getMonth}
              page={this.props.history.push}
              onChange={this.onChange}
              searchedList={searchedList}
              user={this.state.user}
              displayModal={this.state.displayModal}
              ModalMerchant={this.state.ModalMerchant}
              handleOutsideClick={this.handleOutsideClick}
              makeList={this.makeList}
              company={this.state.currentCompany}
              cyberSource={this.state.CyberSource}
              visa={this.state.Visa}
              refreshInfo={this.refreshInfo}
              zohoList={this.state.zohoList}
              admin={admin}
              changeCompany={this.changeCompany}
              moreLeads
            />} />
          )}
          {(
            admin &&
            <Route exact path='/dashboard/reports' render={(props) => <Reports {...props} />} />
          )}


        </Switch>


      </div >
    )


  }
}
export default Dashboard
