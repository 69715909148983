import React from 'react'
import { string, func } from 'prop-types';
import './css/createInputs.scss';

const propTypes = {
  type: string.isRequired,
  title: string.isRequired,
  name: string.isRequired,
  stateValue: string.isRequired,
  onEventChange: func.isRequired,
  placeholder: string
}

const defaultProps = {
  placeholder: 'something'
}

const makeSalesRepList = (reps) => {
  return reps.map(salesRep => {
    return <option value={salesRep.name} > {salesRep.name}</option>
  })
}

const createInputs = ({
  type,
  title,
  name,
  stateValue,
  onEventChange,
  placeholder,
  checked,
  option1,
  option2,
  salesReps,
  ref
}) => {
  if (type === 'input') {
    return (
      <div className="form-details">
        <h4>{title} </h4>
        <div className="form-inputs">
          <input
            placeholder={placeholder || title}
            name={name}
            value={stateValue}
            onChange={onEventChange}
          />
        </div>
      </div>
    )
  }
  if (type === 'radio') {
    return (
      <div className="form-details">
        <h4>{title}</h4>
        <div className='anetCheckbox'>
          <label >
            <input
              checked={checked === option1}
              name={name}
              type='radio'
              value={option1}
              onChange={onEventChange}
            />
            <h5>{option1 === "Visa" ? "Authorize.net" : option1}</h5>
          </label>
          <label >
            <input
              checked={checked === option2}
              name={name}
              type='radio'
              value={option2}
              onChange={onEventChange}
            />
            <h5>{option2}</h5>
          </label>
        </div>
      </div>
    )
  }
  if (type === 'select') {
    return (
      <div className="form-details">
        <h4>{title}</h4>
        <select
          name={name}
          value={stateValue}
          onChange={onEventChange}
        >
          <option value={stateValue}>{stateValue} </option>
          {makeSalesRepList(salesReps)}
        </select>
      </div>
    )
  }
  if (type === 'file') {
    return (
      <div className="form-details">
        <h4>{title}</h4>
        <input
          className='input-file'
          type="file"
          onChange={onEventChange}
          ref={ref}
        />
      </div>
    )
  }
  if (type === 'textArea') {
    return (
      <div className="form-details">
        <h4>{title}</h4>
        <textarea
          className='form-txt-box'
          rows="5"
          cols="70"
          placeholder={placeholder}
          name={name}
          value={stateValue}
          onChange={onEventChange}
        ></textarea>
      </div>
    )
  }
}

createInputs.propTypes = propTypes;
createInputs.defaultProps = defaultProps;

export default createInputs;