import React, { Component } from 'react'
import { getAllAgents } from '../../api/dashBoardApi'

import './Sidebar.scss'


class Sidebar extends Component {

  state = {
    users: [],
    button: false,
    companies: []
  }

  componentDidMount = async () => {
    if (this.props.admin) {
      const { data } = await getAllAgents()
      const companies = data.map(agents => agents.company)
      const filterCompanies = [...new Set(companies)];
      this.setState({ companies: filterCompanies })
    }
  }

  handleClick = (e) => {
    this.setState({ button: !this.state.button })
    let date = new Date()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let currentMonth = `${year}-${month}`

    if (!this.state.button) {
      this.props.getMonth(currentMonth)
    } else {
      this.props.getMonth('')
    }
  }

  handleChangeCompany = ({ target: { value } }) => {
    this.props.changeCompany(value)
    let company = value
    if (value === 'RealtyOne') {
      company = 'Realty One'
    }
    if (value === 'EasyStorageSoftware') {
      company = 'Easy Storage Software'
    }

    this.props.getNewPage(1, null, null, null, company)
  }

  render() {
    const { companies } = this.state
    const { company } = this.props
    const decline = 0;
    const approved = 0;
    const newCount = 0;
    const pending = 0;

    return (
      <div className='Side-wrapper'>
        {/* <button onClick={this.handleClick} className='sideBar-btn' > {(!this.state.button ? 'Current Month' : 'All Time')} </button>
        <h2>{(this.state.button ? 'Current Month' : 'All Time')} </h2>
        <table className='side-table-wrapper'>
          <tbody>
            <tr>
              <th>Status</th>
              <th>#</th>
            </tr>
            <tr >
              <td className='side-approved'>Approved</td>
              <td className='side-approved'>{approved}</td>
            </tr>
            <tr >
              <td className='side-new'>New</td>
              <td className='side-new'>{newCount}</td>
            </tr>
            <tr >
              <td className='side-pending'>Pending</td>
              <td className='side-pending'>{pending}</td>
            </tr>
            <tr >
              <td className='side-declined'>Declined</td>
              <td className='side-declined'>{decline}</td>
            </tr>
          </tbody>

        </table> */}
        <div className='filter-wrapper'>
          {this.props.admin && <div class="admin-change">
            Change Company
            <select onChange={this.handleChangeCompany} value={company}>
              {companies.map(company => {
                return (
                  <option>{company}</option>
                )
              })}
            </select>
          </div>}

          <h3>Filter</h3>
          {this.props.rep.sort().map(rep => {
            return (
              (rep === null ? null :

                <span className='filter-name'>
                  <input
                    type='checkbox'
                    value={rep}
                    checked={this.props.repFilter === rep}
                    onChange={this.props.handleFilter}
                  />
                  <h5 className="saleReps">{rep} </h5>
                  <br />
                </span>
              ))
          })}
        </div>
      </div>
    )
  }
}
export default Sidebar;
