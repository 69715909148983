const headers = [
  "Partner_Merchant IDs",
  "CYBS_MID",
  "Cybersource_Platform",
  "Merchant_Name",
  "Activity_Month",
  "Commission_Month",
  "Currency",
  "Gross_Sales_Amount",
  "Gross_Sales_Items",
  "Basis_Points",
  "Per_Trans_Fee",
  "Start_BPS_Cost",
  "Total_Income",
  "Total_Expense",
  "Gross_Profit",
  "Rev_Share_Type",
  "Rev_Share_Variable",
  "Income_to_CYBS"
]

const anetHeaders = [
  "Partner_Merchant IDs",
  "ANET_MID",
  "ANET_Platform",
  "Merchant_Name",
  "Activity_Month",
  "Commission_Month",
  "Currency",
  "Gross_Sales_Amount",
  "Gross_Sales_Items",
  "Basis_Points",
  "Per_Trans_Fee",
  "Start_BPS_Cost",
  "Total_Income",
  "Total_Expense",
  "Gross_Profit",
  "Rev_Share_Type",
  "Rev_Share_Variable",
  "Income_to_ANET",
  "Processor",
  "Approval Date",
  "MCC"
]

export default { headers, anetHeaders };