import React, { Component } from 'react'
import { Motion, spring, presets } from 'react-motion';
import './modal.scss'

class SendingModal extends Component {





  render() {
    const {
      props: {
        display,
        modalMerchant
      }
    } = this

    return (
      <div >
        <Motion
          style={{

            modalOpacity: spring(display ? 1 : 0, presets.noWobble)
          }}
        >
          {({ modalOpacity }) => (
            <div
              className="modal-container"
              style={{
                opacity: modalOpacity,
                display: modalOpacity === 0 ? 'none' : undefined
              }}
            >
              <div
                ref={node => this.node = node}
                className="inner-modal-container-sending"
              >
                <h1>Sending Lead...</h1>


              </div>
            </div>
          )}
        </Motion>
      </div>
    )
  }
}
export default SendingModal